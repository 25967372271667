<template>
  <FooterCardActionsSlot>
    <section
      class="estimate-actions flex-row ai-c"
      :class="{'jc-sb': spaceBetween && !isTemplateCreate, 'width-100': spaceBetween && !isTemplateCreate}">
      <div class="flex-row ai-c" v-if="!isProjectOwner">
        <ActionItemComponent
          :hideInMobile="true"
          :label="noteFormOpen ? 'Close note form' : noteNotEmpty ? 'View notes' : 'Add notes'"
          fontColor="#4F55F0"
          icon="add-note-icon.svg"
          :hasIconColor="false"
          :disable="isFormReadOnly"
          @call-to-action="addNotes"
        />
        <Counter counter="1" v-if="noteNotEmpty"/>
        <el-divider direction="vertical" />
      </div>
      <div class="flex-row ai-c" v-if="!isTemplateCreate && !isTask">
        <ActionItemComponent
          :hideInMobile="true"
          :label="isProjectOwner ? 'View attachments' : 'Add attachments'"
          fontColor="#4F55F0"
          icon="clip-icon.svg"
          :hasIconColor="false"
          :disable="isFormReadOnly"
          @call-to-action="onAddAttachments"
        />
        <Counter :counter="counter" v-if="counter"/>
        <el-divider direction="vertical" />
      </div>
      <div class="flex-row ai-c" v-if="isTask && !isTemplateCreate">
        <ActionItemComponent
          :hideInMobile="true"
          label="Assign members"
          fontColor="#4F55F0"
          icon="invite-icon.svg"
          :hasIconColor="false"
          :disable="isFormReadOnly"
          @call-to-action="assign"
        />
        <ThumbnailCounter
          v-if="showThumbnailCounter"
          :members="initialAdded.selectedMembers" />
        <el-divider direction="vertical" />
      </div>
    </section>
    <section class="flex-row ai-c" v-if="showDeleteAction">
      <!-- <el-divider direction="vertical" /> -->
      <ActionItemComponent
        icon="trash-icon.svg"
        :hasIconColor="false"
        :disable="isFormReadOnly"
        @call-to-action="onDelete"/>
    </section>
  </FooterCardActionsSlot>
</template>

<script>
import { defineComponent } from 'vue';
import { mapGetters } from 'vuex';

import FooterCardActionsSlot from '@/core/components/slots/FooterCardActionsSlot.vue';
import ActionItemComponent from '@/core/components/ui/ActionItemComponent.vue';
import Counter from '@/core/components/ui/Counter.vue';
import ThumbnailCounter from '@/core/components/ui/ThumbnailCounter.vue';
import { JOB_TEMPLATE_STORE } from '@/store/modules/job-template';

export default defineComponent({
  components: {
    ActionItemComponent,
    FooterCardActionsSlot,
    ThumbnailCounter,
    Counter
  },

  props: [
    'phase',
    'noteFormOpen',
    'isTask',
    'assignMembers',
    'indexes',
    'spaceBetween',
    'isTemplateCreate',
    'noteNotEmpty'
  ],

  emits: ['delete', 'assign-members', 'show-note', 'show-attachment-modal'],

  computed: {
    ...mapGetters(['isProjectOwner']),
    ...mapGetters(JOB_TEMPLATE_STORE, [
      'estimateForm',
      'selectedPhaseIndex',
      'isFormReadOnly'
    ]),

    hasOnlyOnePhase() {
      return !(this.estimateForm?.projectStages && this.estimateForm?.projectStages.length > 1);
    },

    showThumbnailCounter() {
      return this.initialAdded.selectedMembers && this.initialAdded.selectedMembers.length > 0;
    },

    showDeleteAction() {
      return (!this.hasOnlyOnePhase && !this.isTask) || this.isTask;
    },
  },

  data() {
    return {
      initialAdded: {
        phaseIndex: null,
        taskIndex: null,
        selectedMembers: []
      },
      counter: 0,
    };
  },

  watch: {
    assignMembers: {
      immediate: true,
      deep: true,
      handler() {
        this.setMembers();
      },
    },

    estimateForm: {
      deep: true,
      immediate: true,
      handler() {
        this.attachmentCounter();
      }
    }
  },

  created() {
    // this.setMembers();
    this.attachmentCounter();
  },

  methods: {
    addNotes() {
      this.$emit('show-note');
    },

    onAddAttachments() {
      this.$emit('show-attachment-modal');
    },

    assign() {
      this.$emit('assign-members');
    },

    onDelete() {
      if (this.showDeleteAction) {
        this.$emit('delete');
      }
    },

    setMembers() {
      const { assignMembers, indexes } = this;
      const { phaseIndex, taskIndex } = indexes || {};

      if (Array.isArray(assignMembers)) {
        const foundAssignMembers = assignMembers.find(
          (item) => item.phaseIndex === phaseIndex && item.taskIndex === taskIndex
        );

        this.initialAdded = foundAssignMembers || {
          phaseIndex: null,
          taskIndex: null,
          selectedMembers: []
        };
      } else {
        this.initialAdded = {
          phaseIndex: null,
          taskIndex: null,
          selectedMembers: []
        };
      }
    },

    attachmentCounter() {
      let attachments = 0;
      if (this.isProjectOwner) {
        attachments = this.phase?.projectStageAttachments.length;
      } else {
        const projectStages = this.estimateForm?.projectStages[this.selectedPhaseIndex];
        if (projectStages && projectStages?.projectStageAttachments) {
          attachments = projectStages?.projectStageAttachments.length;
        }
      }

      this.counter = attachments;
    },
  },
});
</script>

<style lang="scss" scoped>
.width-100 {
  width: 100%;
}
.el-divider {
  width: 2px;
  color: #0C0F4A;
}
</style>
