import { defineComponent } from 'vue';
import { mapActions, mapGetters } from 'vuex';
import EstimateActions from '@/core/components/common/forms/estimate-form/EstimateActions.vue';
import CollapsibleSlot from '@/core/components/slots/CollapsibleSlot.vue';
import { ProjectStageTypeEnum } from '@/core/packages/shared-library';
import CurrencyFilter from '@/filters/currency.filter';
import PhaseTaskDetailCardComponent from '@/modules/project-details-children/components/PhaseTaskDetailCardComponent.vue';
import { JOB_TEMPLATE_STORE } from '@/store/modules/job-template';
export default defineComponent({
    components: {
        PhaseTaskDetailCardComponent,
        CollapsibleSlot,
        EstimateActions
    },
    emits: ['clicked-phase-index'],
    props: ['data', 'index'],
    data() {
        return {
            ProjectStageTypeEnum,
        };
    },
    computed: {
        ...mapGetters(['isTradesperson']),
        ...mapGetters(JOB_TEMPLATE_STORE, ['showPhaseUploadAttachmentModal']),
        isMaterial() {
            return this.data?.stageType === ProjectStageTypeEnum.MATERIALS;
        }
    },
    methods: {
        ...mapActions(JOB_TEMPLATE_STORE, ['setShowPhaseUploadAttachmentModal']),
        getDays(phase) {
            const totalDays = phase.projectStageTasks.reduce((sum, { duration }) => sum + duration, 0);
            if (totalDays > 0) {
                return totalDays;
            }
            return phase.totalDuration || 0;
        },
        getTotalCost(phase) {
            if (phase.stageType === ProjectStageTypeEnum.MATERIALS) {
                const newSubtotal = 0;
                if (typeof phase.subTotal === 'undefined') {
                    return newSubtotal;
                }
                return CurrencyFilter.formatToCurrency(phase.subTotal.toString());
            }
            const totalEarning = phase.projectStageTasks.reduce((sum, { total }) => sum + total, 0);
            if (totalEarning > 0) {
                return CurrencyFilter.formatToCurrency(totalEarning);
            }
            if (phase?.totalCostWithVat === undefined) {
                return CurrencyFilter.formatToCurrency(phase.stageCost.toString());
            }
            return phase?.totalCostWithVat;
        },
        viewAttachments() {
            this.$emit('clicked-phase-index', this.index);
        },
    },
});
