<template>
  <el-collapse class="collapse-slot" accordion>
    <el-collapse-item name="1">
        <template #title>
            <div class="collapse-slot__header flex-column ai-fs">
                <div class="collapse-slot__wrapper">
                  <slot name="header-one"></slot>
                </div>
                <div class="collapse-slot__wrapper">
                  <slot name="header-two"></slot>
                </div>
            </div>
        </template>
        <div class="collapse-slot__content-wrapper flex-column">
          <slot name="content"></slot>
        </div>
        <div class="collapse-slot__content-wrapper">
          <slot name="empty-state"></slot>
        </div>
        <div class="collapse-slot__content-footer">
          <slot name="footer"></slot>
        </div>
    </el-collapse-item>
  </el-collapse>
</template>
<script>
import { defineComponent } from 'vue';

export default defineComponent({
  props: {
    alignItemsHeader: {
      type: String,
      default: 'center'
    },
    headerGap: {
      type: String,
      default: '.2rem'
    },
    borderRadius: {
      type: String,
      default: '10px'
    },
    headerPadding: {
      type: String,
      default: '1rem .5rem 1rem 1rem',
    },
    contentPadding: {
      type: String,
      default: '0 1.5rem 1.5rem',
    },
    boxShadow: {
      type: String,
      default: '0px 0px 20px rgba(12, 15, 74, 0.1)'
    }
  }
});
</script>
<style lang="scss" scoped>
.collapse-slot {
  border: none;
  background: #FFFFFF;
  box-shadow: v-bind('$props.boxShadow');
  border-radius: v-bind('$props.borderRadius');

  &__header {
    gap: v-bind('$props.headerGap');
    width: 100%;
  }
  &__wrapper {
    width: 100%;
  }
  &__content-wrapper {
    width: 100%;
    // padding: 1.5rem 0 0 0;
    gap: 1.5rem;
  }
  &__content-footer {
    position: absolute;
    bottom: 0;
    right: 0;
    left: 0;
  }
  :deep(.el-collapse-item__header) {
    background-color: transparent;
    gap:1rem;
    outline:none;
    border:1px solid transparent;
    padding: v-bind('$props.headerPadding');
    box-sizing: border-box;
    height: 100%;
    align-items: v-bind('$props.alignItemsHeader');
  }
  :deep(.el-collapse-item__wrap) {
    // padding: 0 1rem 0 1.5rem;
    padding: 0;
    border-bottom: 0;
  }
  :deep(.el-collapse-item__content) {
    padding: v-bind('$props.contentPadding');
    font-family: Mulish;
    font-size: 10px;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0.25px;
    text-align: left;
    color: rgba(12, 15, 74, 0.8);
    position: relative;
    padding-bottom: 4rem;
  }
}
</style>
