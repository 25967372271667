import moment from 'moment';
import { defineComponent } from 'vue';
import BaseModal from '@/core/components/ui/BaseModal.vue';
import CurrencyFilter from '@/filters/currency.filter';
export default defineComponent({
    name: 'service-agreement-modal',
    emits: ['on-confirm', 'on-cancel', 'loading'],
    props: ['project', 'projectQuote', 'loading'],
    components: {
        BaseModal
    },
    data() {
        return {
            readAgreement: false,
        };
    },
    computed: {
        dateNow() {
            return moment(new Date()).format('Do MMM YYYY');
        },
        information() {
            const filter = CurrencyFilter;
            const { project, projectQuote } = this;
            const { name: projectName, refId: projectId, projectCreator, postcode: projectAddress } = project;
            const { projectQuoteCreator, projectStages } = projectQuote;
            const { address: tpAddress, userBusinessDetails } = projectQuoteCreator;
            const { businessName, companyName } = userBusinessDetails;
            const stages = projectStages.map((projectStage) => {
                const { name, stageCostWithFee } = projectStage;
                const rawTotalCost = stageCostWithFee;
                const totalCost = filter.formatToCurrency(rawTotalCost);
                const stageName = name || 'Materials';
                return {
                    name: stageName,
                    totalCost,
                    rawTotalCost
                };
            });
            const totalCostPerStage = stages.map((stage) => stage.rawTotalCost);
            const totalCostSumOfStages = totalCostPerStage.reduce((accumulator, a) => {
                return accumulator + a;
            }, 0);
            return {
                projectName,
                projectId,
                clientName: `${projectCreator.firstName} ${projectCreator.lastName}`,
                projectAddress,
                tpCompanyName: (companyName || businessName) || `${projectQuoteCreator.firstName} ${projectQuoteCreator.lastName}`,
                tpAddress,
                stages,
                totalCostSumOfStages: filter.formatToCurrency(totalCostSumOfStages)
            };
        }
    },
    methods: {
        onConfirm() {
            this.$emit('on-confirm');
        },
        onCancel() {
            this.$emit('on-cancel');
        },
    }
});
