<template>
    <el-card class="task-card">
        <div class="task-summary-content">
            <div class="header">
               <div class="header__task-label">
                  <p class="default-text">{{ isMaterial ? `Item ${index}` : `Task ${index}` }}</p>
               </div>
               <p class="header__title default-text">{{ task.name }}</p>
            </div>
            <div class="summary flex-row ai-c">
              <p class="summary__data default-text" v-if="!isMaterial && task.duration">{{ task.duration }} days <span>Durations</span></p>
              <p class="summary__data default-text" v-if="isMaterial && task.quantity">{{ task.quantity }} <span>Quantity</span></p>
              <!-- <el-divider direction="vertical"/>
              <p class="summary__data default-text" v-if="task.amount">£{{ task.amount }} <span>Unit costs</span></p> -->
              <el-divider direction="vertical"/>
              <p class="summary__data default-text blue">{{ $filters.formatToCurrency(task.amount) }} <span>Total task cost</span></p>
            </div>
        </div>
    </el-card>
</template>
<script>

export default {
  props: ['bgColor', 'color', 'index', 'task', 'isMaterial'],

  computed: {
  }
};
</script>
<style lang="scss" scoped>
@use "../../../assets/scss/mixins/media-query" as *;
@use "../../../assets/scss/mixins/" as *;

.task-card.is-always-shadow {
    box-shadow: none !important;
}
.el-card {
    background: rgba(12, 15, 74, 0.05);
    border-radius: 10px !important;
    padding: 0;
    border: none;
}
.el-divider {
    margin: 0;
}
.task-summary-content {
    display: flex;
    flex-direction: column;
    gap: 0;
}
.header {
    display: flex;
    gap: .7rem;
    align-items: center;
}
.header__task-label {
    // background: v-bind('$props.bgColor');
    background: rgba(74, 146, 229, 0.1);
    width: 58px;
    height: 22px;
    border-radius:2px;
    display: flex;
    align-items: center;
    justify-content: center;

    p {
        font-size: 10px;
        font-weight: 600;
        line-height: 25px;
        letter-spacing: 0.25px;
        text-align: center;
        // color: v-bind('$props.color');
        color: #4A92E5;
    }
}
.header__title {
    font-size: 12px;
    font-weight: 600;
    line-height: 30px;
    letter-spacing: 0px;
    text-align: left;
}

.summary {
    gap: .5rem;
}
.summary__data {
    font-size: 14px;
    font-weight: 600;
    line-height: 25px;
    letter-spacing: 0.25px;
    text-align: right;

    span {
        font-size: 10px;
        font-weight: 400;
        line-height: 25px;
        letter-spacing: 0.25px;
        text-align: left;
        color: rgba(12, 15, 74, 0.7);
    }
}

.blue {
    color: #4F55F0;
}

:deep(.el-card__body) {
   height: 100% !important;
   box-sizing: border-box !important;
}

@include media-xs-max-width() {
    .summary {
        flex-direction: column;
        width: 100%;
    }
    .summary__data {
        width: 100%;
        display: flex;
        flex-direction: row-reverse;
        justify-content: space-between;
    }
}
</style>
